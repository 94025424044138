import {
  USER_LOGIN,
  USER_LOGOUT,
  SET_CURRENT_USER,
  USER_TOGGLE_LOGOUT_MODAL,
  SET_DAYEND_DATE,
  FETCH_CURRENT_USER_START,
  FETCH_CURRENT_USER_STOP,
  FETCH_CURRENT_USER_ERROR,
  SET_TOKEN, TOGGLE_NOTICE_MODAL
} from "../actions/types";

const initialState = {
  is_fetching_user: false,
  user_fetch_error: false,
  user: {},
  staff: {},
  day_closure_bs_date: "",
  day_closure_ad_date: "",
  permissions: [],
  fiscal_year: {},
  roles: [],
  head_office_list: [],
  mart_office_list: [],
  jbl_office_list: [],
  jbl_district_office_list: [],
  notice_list: [],
  show_notice_modal: false,
  show_password_change_modal: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload,
      };
    case USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: {},
        staff: {},
        is_fetching_user: false,
        user_fetch_error: false,
      };
    case USER_TOGGLE_LOGOUT_MODAL:
      return {
        ...state,
        showLogoutModal: !state.showLogoutModal,
        exitApp: action.payload
      };
    case FETCH_CURRENT_USER_START:
      return {
        ...state,
        is_fetching_user: true,
        user_fetch_error: false,
      };
    case FETCH_CURRENT_USER_STOP:
      return {
        ...state,
        is_fetching_user: false,
        user_fetch_error: false,
      };
    case FETCH_CURRENT_USER_ERROR:
      return {
        ...state,
        is_fetching_user: false,
        user_fetch_error: true,
      };
    case SET_CURRENT_USER:
      let user = action.payload;
      return {
        ...state,
        user: user,
        user_type: user.user_type,
        staff: user.staff ? user.staff : {},
        roles: user.roles ? user.roles : [],
        fiscal_year: user.fiscal_year ? user.fiscal_year : {},
        day_closure_bs_date: user.day_closure ? user.day_closure.bs_date : "",
        day_closure_ad_date: user.day_closure ? user.day_closure.ad_date : "",
        day_closure_day_name: user.day_closure ? user.day_closure.day : "",
        head_office_list: user.head_office_list ? user.head_office_list : [],
        mart_office_list: user.mart_office_list ? user.mart_office_list : [],
        branch_office_list: user.branch_office_list ? user.branch_office_list : [],
        jbl_office_list: user.jbl_office_list ? user.jbl_office_list : [],
        jbl_branch_office_list: user.jbl_branch_office_list ? user.jbl_branch_office_list : [],
        jbl_district_office_list: user.jbl_district_office_list ? user.jbl_district_office_list : [],
        notice_list: user.notice_list,
        permissions: user.user_permission_list,
        // show_notice_modal: user.notice_list.length > 0
        show_password_change_modal: user.password_change
      };
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload,
      };
    case TOGGLE_NOTICE_MODAL:
      return {
        ...state,
        show_notice_modal: !state.show_notice_modal,
      };
    default:
      return state
  }
}
